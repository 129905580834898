import * as React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import { Button, Icon } from 'components/ui'
import { BpmIndicationQuery } from 'generated-models'
import { FormikProps } from 'formik'

const StepInner = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const PreviousButton = styled.a`
  color: white;
  text-decoration: underline;
  cursor: pointer;
`

interface RenderStepArgs {
  bpmIndication: BpmIndicationQuery['bpmIndication']
  formikProps: FormikProps<any>
  nextStep: () => void
  previousStep: () => void
  step: number
  previousAction?: (e: BpmIndicationQuery['bpmIndication']) => void
  nextAction?: (e: BpmIndicationQuery['bpmIndication']) => void
  hashedOrderNumber: string
}

interface RenderStepProps {
  component: React.ElementType
  next?: string | boolean
  previous?: string | boolean | React.ElementType
}

const Step: React.SFC<RenderStepArgs & RenderStepProps & { i: number }> = ({
  bpmIndication,
  formikProps,
  nextStep,
  step,
  previousStep,
  previous = true,
  next = true,
  previousAction,
  nextAction,
  component: Component,
  hashedOrderNumber,
  i,
}) => {
  return (
    <Flex
      flex="1 0 100%"
      mr={[4]}
      sx={{ maxHeight: step === i ? 700 : 0, transition: 'all 0.3s ease-out' }}
    >
      <StepInner>
        <Flex flex="1" flexDirection="column">
          <Component
            formikProps={formikProps}
            nextStep={nextStep}
            bpmIndication={bpmIndication}
          />
        </Flex>
        <Flex
          alignItems={['flex-end', 'initial']}
          marginTop={(previous || next) && 30}
        >
          <Box width={[2 / 12, 4 / 12]}>
            {previous ? (
              previous instanceof Function ? (
                React.createElement(previous, { hashedOrderNumber })
              ) : (
                <PreviousButton
                  onClick={() =>
                    previousAction
                      ? previousAction(
                          formikProps.values as BpmIndicationQuery['bpmIndication']
                        )
                      : previousStep()
                  }
                >
                  {typeof previous === 'string' ? previous : 'Terug'}
                </PreviousButton>
              )
            ) : null}
          </Box>
          <Box width={[10 / 12, 8 / 12]}>
            {next && (
              <Button
                loading={formikProps.isSubmitting}
                onClick={() =>
                  nextAction
                    ? nextAction(
                        formikProps.values as BpmIndicationQuery['bpmIndication']
                      )
                    : formikProps.handleSubmit()
                }
                suffix={
                  <Icon
                    color="white"
                    style={{ marginTop: 8 }}
                    icon="arrow-right"
                  />
                }
              >
                {typeof next === 'string' ? next : 'Volgende'}
              </Button>
            )}
          </Box>
        </Flex>
      </StepInner>
    </Flex>
  )
}

const renderStep = (args: RenderStepArgs) => (
  props: RenderStepProps,
  i: number
) => {
  return <Step key={i} {...args} {...props} i={i} />
}

export default renderStep
