import React from 'react'
import styled from '@emotion/styled'
import { FC } from 'react'
import { useField } from 'formik'

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const CheckBoxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const StyledCheckBox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${props =>
    props.checked ? props.theme.colors.primary : 'whitesmoke'};
  border-radius: 3px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px white;
  }
  ${CheckBoxIcon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

interface CheckBoxProps extends React.InputHTMLAttributes<{}> {}

const CheckBox: FC<CheckBoxProps> = props => {
  const [field] = useField(props.name)
  return (
    <>
      <CheckboxContainer>
        <HiddenCheckbox type="checkbox" checked={field.value} {...field} />
        <StyledCheckBox {...props} checked={field.value}>
          <CheckBoxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </CheckBoxIcon>
        </StyledCheckBox>
      </CheckboxContainer>
    </>
  )
}

export default CheckBox
