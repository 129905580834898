/** @jsx jsx */
import React from 'react'
import { Heading } from 'rebass'
import { StepProps } from '../types'
import { jsx } from 'theme-ui'

const SplashScreen: React.SFC<StepProps> = ({ bpmIndication }) => {
  return (
    <div>
      <Heading mb={3} as="h3" style={{ color: 'white' }}>
        Uw BPM indicatie
      </Heading>
      <p>
        Dank voor uw BPM aanvraag. Dit is een BPM berekening op basis van de
        afschrijvingstabel of waardebepaling van de Belastingdienst. De voor u
        meest gunstige vorm is gekozen. Omdat wij werken met verschillende
        koerslijsten is er bij jonge auto&apos;s vaak nog meer winst te behalen.
        Vraag hiertoe een offerte aan.
      </p>
      <table
        sx={{
          mt: 3,
          'tr > td:first-child': {
            ':after': {
              content: '":"',
            },
            pr: 3,
          },
        }}
      >
        <tbody>
          <tr>
            <td>Merk</td>
            <td>{bpmIndication.car.brand.name}</td>
          </tr>
          <tr>
            <td>Model</td>
            <td>{bpmIndication.car.model}</td>
          </tr>
          <tr>
            <td>BPM prijs</td>
            <td>&euro;{bpmIndication.car.bpmPrice}</td>
          </tr>
          <tr>
            <td>Auto prijs</td>
            <td>&euro;{bpmIndication.car.carPrice}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SplashScreen
