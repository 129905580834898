import * as React from 'react'
import Lottie from 'react-lottie'
import Checkmark from 'lottie/checked-done.json'

const Success = () => {
  return (
    <>
      <h3>Dank u voor uw offerte aanvraag.</h3>
      <p>U ontvangt een email met daarin de ontvangstbevestiging.</p>
      <Lottie options={{ loop: false, animationData: Checkmark }} />
    </>
  )
}

export default Success
