/** @jsx jsx */
import { jsx } from 'theme-ui'
import { PricePackagesQuery, usePricePackagesQuery } from 'generated-models'
import Select from 'react-select'
import { Field, FieldProps } from 'formik'
import { ValueType } from 'react-select/src/types'

const ChoosePricePackage = () => {
  const { loading, error, data } = usePricePackagesQuery()
  return (
    <div>
      <p>
        Selecteer het gewenste prijspakket dat u bij uw import wenst. Voor meer
        informatie over de prijspakketten kijkt u op onze{' '}
        <a href="/" target="_blank">
          home-pagina
        </a>
        . Mocht u nog vragen hebben, dan kunt u ten allen tijden contact
        opnemen.
      </p>
      {!error && data && (
        <Field
          name="pricePackageId"
          render={({ field, form }: FieldProps) => (
            <Select
              classNamePrefix="react-select"
              sx={{
                mt: 3,
                color: 'text',
              }}
              {...field}
              isLoading={loading}
              value={
                data.pricePackages &&
                data.pricePackages.find(option => option.id === field.value)
              }
              onChange={(
                value: ValueType<PricePackagesQuery['pricePackages'][0]>
              ) =>
                form.setFieldValue(
                  field.name,
                  (value as PricePackagesQuery['pricePackages'][0]).id
                )
              }
              placeholder="Seleteer een prijspakket..."
              getOptionLabel={e => e.name}
              getOptionValue={e => e.id}
              options={!loading && data.pricePackages}
            />
          )}
        />
      )}
    </div>
  )
}

export default ChoosePricePackage
