import * as React from 'react'
import ReactDOM from 'react-dom'
import { useCancelOrderMutation } from '../../generated-models'
import { CSSTransition } from 'react-transition-group'
import { Box, Icon, TextArea, Button } from 'components/ui'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { Flex, Box as ReBox } from 'rebass'
import { RouteComponentProps, Router, navigate } from '@reach/router'

const transitionName = 'cancelModal'

const CancelButton = styled.a`
  text-decoration: underline;
  color: white;
  cursor: pointer;
`

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
  & > * {
    transform: rotate(45deg);
  }
`

const BoxWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  &.${transitionName}-enter {
    transform: scale(0);
    opacity: 0;
  }
  &.${transitionName}-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 300ms ease-in;
  }
  &.${transitionName}-exit {
    transform: scale(1);
    opacity: 1;
  }
  &.${transitionName}-exit-active {
    opacity: 0;
    transform: scale(0);
    transition: all 300ms ease-in;
  }
`

const CancelOrder = (
  props: RouteComponentProps<{ hashedOrderNumber: string }>
) => {
  const [showCancel, setShowCancel] = React.useState(false)
  const [cancelOrder] = useCancelOrderMutation()

  return (
    <>
      <CancelButton onClick={() => setShowCancel(true)}>
        Anuleer de import
      </CancelButton>
      {ReactDOM.createPortal(
        <CSSTransition
          unmountOnExit
          in={showCancel}
          classNames={transitionName}
          timeout={500}
        >
          <BoxWrapper>
            <Box width={700} style={{ position: 'relative' }}>
              <h3>Annuleer de import</h3>
              <Close onClick={() => setShowCancel(false)}>
                <Icon icon="plus" color="white" />
              </Close>
              <p>
                Jammer dat u van de import afziet, als er iets waar u nog vragen
                over heeft dan kunt u altijd contact met ons opnemen. <br />
                <br /> Zou u misschien een reden willen opgeven waarom u de
                import afslaat? Dan kunnen wij hiermee onze service in de
                toekomst verbeteren.
              </p>
              <Formik
                initialValues={{ reason: '' }}
                onSubmit={async (values, actions) => {
                  try {
                    await cancelOrder({
                      variables: {
                        hashedOrderNumber: props.hashedOrderNumber,
                        reason: values.reason,
                      },
                    })
                  } catch (error) {
                    console.log(error)
                  }
                  actions.setSubmitting(false)
                  navigate('/')
                }}
                render={props => (
                  <>
                    <TextArea
                      name="reason"
                      {...props}
                      placeholder="Reden van annulatie"
                      fieldProps={{
                        style: { color: 'white' },
                        icon: 'pen',
                      }}
                    />
                    <Flex alignItems={['flex-end', 'initial']}>
                      <ReBox>
                        <Button
                          loading={props.isSubmitting}
                          style={{ margin: '0 10px' }}
                          onClick={() => props.handleSubmit()}
                        >
                          Annuleer de import
                        </Button>
                      </ReBox>
                    </Flex>
                  </>
                )}
              />
            </Box>
          </BoxWrapper>
        </CSSTransition>,
        document.querySelector('body')
      )}
    </>
  )
}

const CancelOrderWithRouter = () => (
  <Router>
    <CancelOrder path="/bpmindicatie/:orderNumber" />
  </Router>
)

export default CancelOrderWithRouter
