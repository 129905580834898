/** @jsx jsx */
import { StepProps } from '../types'
import CheckBox from 'components/ui/CheckBox'
import { TermsAndConditionsText } from '../../../constants'
import { Box } from 'rebass'
import { jsx } from 'theme-ui'

const TermsAndConditions: React.SFC<StepProps> = () => {
  return (
    <div>
      <p sx={{ mb: 2 }}>
        Bij het gebruik maken van onze service gaat u akkoord met onze algemene
        voorwaarden:
      </p>
      <Box
        maxHeight={400}
        overflowY="auto"
        p={10}
        mb={2}
        fontSize={14}
        sx={{ border: '1px solid white', borderRadius: 5 }}
      >
        {TermsAndConditionsText}
      </Box>
      <label>
        <CheckBox name="acceptTermsAndConditions" style={{ marginRight: 10 }} />
        <span style={{ color: 'white' }}>
          Ik ga akkoord met de algemene voorwaarden
        </span>
      </label>
    </div>
  )
}

export default TermsAndConditions
