import * as React from 'react'
import { StepProps } from '../types'

const SendQuotation: React.SFC<StepProps> = ({ formikProps }) => {
  return (
    <>
      <p>
        Dat was het! <br /> <br />
        Nadat u op 'Verstuur offerte' heeft geklikt ontvangt u een email met een
        bevestiging van de offerte aanvraag. Wij gaan z.s.m. aan de slag om de
        offerte in orde te maken zodat u, des gewenst, de import snel voort kunt
        zetten. Mocht u nog vragen hebben dan kunt u altijd contact met ons
        opnemen via{' '}
        <a href="mailto:PC Auto Import <info@pcautimport.nl>">
          info@pcautimport.nl
        </a>{' '}
        of +31 (0) 20 244 1586.
      </p>
    </>
  )
}

export default SendQuotation
