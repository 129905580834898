import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import { Box } from 'rebass'

const transitionName = `error`

const Error = styled(Box)`
  display: flex;
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
  position: absolute;
  z-index: 100;
  width: calc(100% - 60px);
  border-radius: 5px;
  padding: 15px;
  &.${transitionName}-enter {
    transform: translateY(-20px);
    opacity: 0;
  }
  &.${transitionName}-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 500ms ease-in;
  }
  &.${transitionName}-exit {
    transform: translateY(0);
    opacity: 1;
  }
  &.${transitionName}-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: all 500ms ease-in;
  }
`

const Errors: React.SFC<{ errors: string[] }> = ({ errors }) => {
  return (
    <TransitionGroup appear>
      {errors.map((error, i) => (
        <CSSTransition key={i} timeout={500} classNames={transitionName}>
          <Error color="text">
            <span>{error}</span>
          </Error>
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default Errors
