import React from 'react'
import {
  BpmIndicationQuery,
  useRequestQuotationMutation,
  useBpmIndicationQuery,
} from '../generated-models'
import styled from '@emotion/styled'
import { Formik, FormikHelpers } from 'formik'
import { rgba } from 'polished'
import SplashScreen from 'components/BpmIndicatie/steps/splashScreen'
import ChoosePricePackage from 'components/BpmIndicatie/steps/choosePricePackage'
import TermsAndConditions from 'components/BpmIndicatie/steps/termsAndConditions'
import * as Yup from 'yup'
import Errors from 'components/BpmIndicatie/errors'
import renderStep from 'components/BpmIndicatie/renderStep'
import SendQuotation from 'components/BpmIndicatie/steps/sendQuotation'
import Logo from 'components/ui/Logo'
import Success from 'components/BpmIndicatie/success'
import CancelOrder from 'components/BpmIndicatie/CancelOrder'
import { Router, RouteComponentProps, navigate } from '@reach/router'
import { Flex, Box } from 'rebass'
import { graphql, useStaticQuery } from 'gatsby'
import { useThemeUI } from 'theme-ui'
import { Helmet } from 'react-helmet'

const StepIndicatorWrapper = styled.div<{ hidden: boolean }>`
  display: flex;
  align-items: space-around;
  width: 100%;
  margin-bottom: 20px;
  transform: translateY(0);
  transition: transform 0.3s ease-out;
  ${({ hidden }) =>
    hidden &&
    `
    transform: translateY(-100px);
  `}
`

const StepIndicator = styled.div<{ active: boolean }>`
  color: #afafaf;
  display: flex;
  align-items: center;
  flex: 1 0 ${({ active }) => (active ? '20%' : 0)};
  margin: 0 10px;
  box-shadow: inset -4px 1px 9px -6px rgba(10, 10, 10, 0.1);
  border-bottom: 2px solid #afafaf;
  padding: 10px 0;
  overflow: hidden;
  transition: flex-basis 0.3s ease-out;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  & > span {
    width: 100%;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
  }
  ${({ active }) =>
    active &&
    `
    color: white;
    border-bottom: 2px solid white;
  `}
`

interface Step {
  name: string
  component: React.ElementType
  validationSchema?: Yup.ObjectSchema<Yup.Shape<{}, {}>>
  indicator?: boolean
  next?: string | boolean
  previous?: string | boolean | React.ElementType
  nextAction?: (e: BpmIndicationQuery['bpmIndication']) => void
  previousAction?: (e: BpmIndicationQuery['bpmIndication']) => void
}

const steps: Step[] = [
  {
    name: '',
    component: SplashScreen,
    indicator: false,
    previous: CancelOrder,
    next: 'Vraag offerte aan',
    // eslint-disable-next-line no-console
    previousAction: (e) => console.log(e),
  },
  {
    name: 'Prijs Pakket',
    component: ChoosePricePackage,
    validationSchema: Yup.object().shape({
      pricePackageId: Yup.string().required('Selecteer een prijspakket.'),
    }),
  },
  {
    name: 'Algemene voorwaarden',
    component: TermsAndConditions,
    validationSchema: Yup.object().shape({
      acceptTermsAndConditions: Yup.bool().oneOf(
        [true],
        'Ga akkoord met de algemene voorwaarden.'
      ),
    }),
  },
  {
    name: 'Verstuur aanvraag',
    component: SendQuotation,
    next: 'Verstuur aanvraag',
  },
]

const BpmIndicatieInfo: React.SFC<RouteComponentProps<{
  hashedOrderNumber: string
}>> = ({ hashedOrderNumber }) => {
  const [step, setStep] = React.useState(0)
  const [success, setSuccess] = React.useState(false)
  const { loading, error, data } = useBpmIndicationQuery({
    variables: { hashedOrderNumber },
  })
  const [requestQuotation] = useRequestQuotationMutation()
  const initialValues = {
    pricePackageId: '',
    acceptTermsAndConditions: false,
  }
  const gatsbyData = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          banner {
            backgroundImage
          }
        }
      }
    }
  `)
  const { theme } = useThemeUI()
  const nextStep = () => {
    setStep(step + 1)
  }
  const isLastStep = () => {
    return step === steps.length - 1
  }
  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<any>
  ) => {
    const { setSubmitting } = actions

    if (!isLastStep()) {
      setSubmitting(false)
      nextStep()
      return
    }

    try {
      await requestQuotation({
        variables: {
          ...values,
          hashedOrderNumber,
        },
      })
      setSuccess(true)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }

    setSubmitting(false)
  }
  const previousStep = () => setStep(step - 1)
  if (loading) return <div>Loading...</div>
  if (error) {
    navigate('/')
    return null
  }
  return (
    <>
      <Helmet
        title="BPM indicatie"
        meta={[{ name: 'robots', content: 'noindex' }]}
      />
      <Flex
        height="100vh"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: `linear-gradient(to bottom right, ${rgba(
            theme.colors.secondary,
            0.5
          )}, ${rgba(theme.colors.primary, 0.5)}), center/cover no-repeat url(${
            gatsbyData.markdownRemark.frontmatter.banner.backgroundImage
          })`,
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, left: 0 }} p={[20]}>
          <Logo />
        </Box>
        <Box
          variant="content"
          maxWidth={600}
          sx={{ position: 'relative', overflowX: 'hidden' }}
        >
          {!success ? (
            <>
              <StepIndicatorWrapper hidden={steps[step].indicator === false}>
                {steps.map(
                  ({ name }, i) =>
                    name && (
                      <StepIndicator key={name} active={i === step}>
                        <span>{name}</span>
                      </StepIndicator>
                    )
                )}
              </StepIndicatorWrapper>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={
                  steps[step].validationSchema || Yup.object().shape({})
                }
                render={(props) => {
                  return (
                    <>
                      <Errors errors={Object.values(props.errors)} />
                      <Flex
                        sx={{
                          transform: `translateX(calc(-${step * 100}% - ${
                            step * 32
                          }px))`,
                          marginTop: steps[step].indicator === false ? -50 : 0,
                          transition: 'all 0.3s ease-out',
                        }}
                      >
                        {steps.map(
                          renderStep({
                            bpmIndication: data.bpmIndication,
                            formikProps: props,
                            nextStep,
                            previousStep,
                            step,
                            hashedOrderNumber,
                          })
                        )}
                      </Flex>
                    </>
                  )
                }}
              />
            </>
          ) : (
            <Success />
          )}
        </Box>
      </Flex>
    </>
  )
}

const BpmIndicatieInfoWithRouter = () => (
  <Router>
    <BpmIndicatieInfo path="/bpmindicatie/:hashedOrderNumber" />
  </Router>
)

export default BpmIndicatieInfoWithRouter
